<template>
  <PrimaryModal v-bind="$attrs" v-on="$listeners">
    <v-card-text>
      <p>
        按鈕一經變更則無法復原
      </p>
    </v-card-text>

    <v-card-text>
      <div class="d-flex justify-center">
        <v-btn min-width="100px" color="primary" @click="apply">確認</v-btn>
      </div>
    </v-card-text>
  </PrimaryModal>
</template>

<script>
import PrimaryModal from "components/modal/primaryModal.vue";
import modalMixins from "components/modal/modal"
export default {
  mixins: [modalMixins],
  props: {

  },
  components: {
    PrimaryModal,
  },

}
</script>
